<template>
  <section>
    <va-breadcrumbs>
        <va-breadcrumbs-item label="Educacao" to="/admin/educacao/segmentos" />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: #0785d3"
            >Adicionar Aluno na Turma</span
          >
        </va-breadcrumbs-item>
    </va-breadcrumbs>

    <h2 class="titulo-links-escolha">Adicionar Aluno na Turma</h2>
    <p></p>
    <div class="row">
      <div :class="'col-md-12'">
          <div class="flex md4 sm6 xs12">
                <div class="form-group">
                    <label for="sel1">Selecione a Escola:</label>
                    <select class="form-control" v-model="aluno.escola">
                        <option v-for="n in escolaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                </div>
                <div class="form-group" v-if="segmentosALL">
                    <label for="sel1">Selecione a etapa:</label>
                    <select class="form-control" v-model="aluno.segmento" @change="listaSerie(aluno.segmento)">
                        <option v-for="n in segmentosALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                </div>
                <div class="form-group" v-if="seriesALL.length">
                    <label for="sel1">Selecione o período:</label>
                    <select class="form-control" v-model="aluno.serie"  @change="buscarTurmas(aluno.segmento)">
                        <option v-for="n in seriesALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                </div>

                <div class="form-group" v-if="turmasALL.length">
                  <label for="sel1">Selecione a turma:</label>
                  <select class="form-control" v-model="aluno.turma">
                    <option v-for="n in turmasALL" :key="n" :value="n.id" >{{n.nome}}</option>
                  </select>
                </div>

                <div class="flex" >
                    <va-button class="mr-2 mb-2"  @click="adicionarAlunoTurma"> {{ $t('Adicionar') }}</va-button>
                </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Aluno } from "@/class/alunos.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Calendario } from "@/class/calendario";
import { Escola } from "@/class/escolas";
import { Turma } from "@/class/turma";
import store_token_info from "@/store/store_token_info.js";


export default defineComponent({
  props: {
    id:{},
  },
  data() {
    return {
      aluno:[],
      segmentosALL:[],
      seriesALL:[],
      escolaALL:[],
      turmasALL:[],
    };
  },
  methods: {
    async adicionarAlunoTurma(){
      try {
        let payload = {
          ano: this.aluno.ano,
          aluno_id: this.id,
          turma_id: this.aluno.turma,
          user_id: store_token_info.usuario_logado,
        }
        try {
          let data = await Aluno.addAlunoTurma(payload);
          this.$vaToast.init({
            message: "Cadastrado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            fullWidth: false,
          })
          this.$router.push({name: 'alunosAll'});
        }
        catch(e) {
          this.$vaToast.init({
            message: "Erro ao cadastrar!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            fullWidth: false,
          })
        }
      } catch (e) {
      }
    },
    async listaEscola(){
      try {
        let data = await Escola.obtemTodos();
        this.escolaALL = data.data;
      } catch (e) {}
    },

    async listaSegmentos(){
      try {
        let data = await SegmentoEscolar.obtemTodos();
        this.segmentosALL = data.data;
      } catch (e) {}
    },

    async listaSerie (id) {
      let data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      this.seriesALL = data.data;
      return this.seriesALL;
    },
    async listaCalendario(){
      try {
        let data = await Calendario.calendarioAtual();

        this.aluno.ano = data.data.ano;
      } catch (e) {}
    },

    async listarTudo() {
      try {
        let data = await Aluno.obtemTodos();
        this.item = data.data;
      } catch (e) {}
    },
    async buscarTurmas() {
      let payload = {
        ano: this.aluno.ano,
        escola_id: this.aluno.escola,
        segmento_id: this.aluno.segmento,
        serie_id: this.aluno.serie,
      }
      let data = await Turma.turmasAvancada(payload);
      this.turmasALL = data.data;
    }
  },
  beforeMount() {
    if (this.id == null) {
        this.$router.push({name: 'alunosAll'});
    }
    else {
      this.listarTudo();
      this.listaEscola();
      this.listaSegmentos();
      this.listaCalendario();
    }
  }
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
